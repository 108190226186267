import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box, Image, Typography } from '../..';
import websiteCopy from '../../../copy';

import logo from '../../../assets/Logo-register.png';
import heroImg from '../../../assets/Hero.png';

const StyledHeroWrapper = styled(Box)(
  css({
    display: ['block', 'block', 'block', 'block', 'flex'],
    alignItems: 'center',
    paddingRight: [0, 0, 60, 165, 0],
    paddingLeft: [0, 0, 60, 165, 165, 370],
    paddingTop: ['143px'],
    paddingBottom: [48],
    height: [800, 1000, 1100, 1080],
    background: [
      `no-repeat url(${logo}) bottom right, linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)`,
      `no-repeat url(${logo}) bottom right, linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)`,
      `no-repeat url(${logo}) bottom left, linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)`
    ]
  })
);

const StyledHeroText = styled(Box)(
  css({
    px: ['5'],
    mb: [0, 0, 120, 0, 200],
    mt: [0, 0, 0, 50],
    flex: '0 1 480px'
  })
);

const StyledHeroBg = styled(Box)(
  css({
    flexGrow: 1,
    textAlign: 'right',
    marginLeft: [0, 0, 0, 0, '50px', '102px'],
    marginTop: ['70px'],
    height: ['274px', 'auto', 'auto']
  })
);

export const HomeHero = () => (
  <StyledHeroWrapper>
    <StyledHeroText>
      <Typography
        variant="display"
        color="light"
        fontSize={['48px', '48px', '72px']}
        letterSpacing="1px"
        lineHeight={['48px', '84px']}
      >
        { websiteCopy('HOME_PAGE_HEADER') }
      </Typography>
      <Typography color="light" marginTop={['6']}>
      { websiteCopy('HOME_PAGE_DESCRIPTION') }
      </Typography>
    </StyledHeroText>
    <StyledHeroBg>
      <Image src={heroImg} width="100%" maxWidth="960px" />
    </StyledHeroBg>
  </StyledHeroWrapper>
);
