import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import websiteCopy from '../../../copy';

import { Box, Flex, Image, Typography } from '../..';
import feature1 from '../../../assets/Feature-01.png';
import feature2 from '../../../assets/Feature-02.png';
import feature3 from '../../../assets/Feature-03.png';

const Separator = props => (
  <Box {...props} height="6px" width="60px" bg="accent" />
);

const ImageBox = styled(Box)(
  css({
    textAlign: 'center',
    flexShrink: 0
  })
);

export const HomeFeatures = () => (
  <Box
    bg="white"
    py={['90px', '90px', '220px']}
    px={['5', '5', '80px', '100px', '200px', '370px']}
  >
    <Flex flexDirection="column">
      <Flex.Item flexDirection={['column', 'column', 'column', 'row']}>
        <Box>
          <Separator />
          <Typography variant="subhead" mt={['6']}>
            { websiteCopy('FEATURES_1_HEADER') }
          </Typography>
          <Typography mt={['6']}>
          { websiteCopy('FEATURES_1_DESCRIPTION') }
          </Typography>
        </Box>
        <ImageBox mt={[8]} marginLeft={[0, 0, 120]}>
          <Image src={feature1} height={['227px', '227px', '364px']} />
        </ImageBox>
      </Flex.Item>
      <Flex.Item
        flexDirection={['column', 'column', 'column', 'row-reverse']}
        mt={['90px', '90px', '242px']}
      >
        <Box>
          <Separator />
          <Typography variant="subhead" mt={['6']}>
          { websiteCopy('FEATURES_2_HEADER') }
          </Typography>
          <Typography mt={['6']}>
          { websiteCopy('FEATURES_2_DESCRIPTION') }
          </Typography>
        </Box>
        <ImageBox mt={[8]} marginRight={[0, 0, 120]}>
          <Image src={feature2} height={['227px', '227px', '364px']} />
        </ImageBox>
      </Flex.Item>
      <Flex.Item
        flexDirection={['column', 'column', 'column', 'row']}
        mt={['90px', '90px', '242px']}
      >
        <Box>
          <Separator />
          <Typography variant="subhead" mt={['6']}>
          { websiteCopy('FEATURES_3_HEADER') }
          </Typography>
          <Typography mt={['6']}>
          { websiteCopy('FEATURES_3_DESCRIPTION') }
          </Typography>
        </Box>
        <ImageBox marginLeft={[0, 0, 120]}>
          <Image mt={[8]} src={feature3} height={['227px', '227px', '364px']} />
        </ImageBox>
      </Flex.Item>
    </Flex>
  </Box>
);
