import { useRef, useEffect } from 'react';

function useWatchRef(value) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}

export default useWatchRef;
