import React, { useState } from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Box, Button, Icon, Image, Input, Field, Typography } from '../..';
import logo from '../../../assets/Logo-register.png';
import websiteCopy from '../../../copy';
import { useForm } from '../../../hooks/useForm';
import { SignUpMutation } from '../../../mutations/SignUpMutation';
import { useMutation } from '@apollo/react-hooks';

const Wrapper = styled(Box)(
  css({
    position: 'relative',
    px: ['5', '5'],
    paddingTop: ['90px', '90px', '144px'],
    paddingBottom: ['90px', '90px', '144px'],
    background:
      'linear-gradient(-45deg, rgb(9, 80, 186) 0%, rgb(51, 204, 255) 100%)'
  })
);
const validates = {
  email: formValues =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      formValues.email
    )
      ? false
      : 'Please enter a valid email address.'
};

export const SignUp = props => {
  const {
    formValues,
    formErrors,
    onChanges,
    onReset,
    createOnSubmit
  } = useForm(
    {
      email: ''
    },
    validates
  );

  // const [signUp] = useMutation(SignUpMutation);
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = createOnSubmit(variables => {
    const url = `${process.env.GATSBY_APP_URL}/signup?email=${variables.email}`;
    var win = window.open(url, '_blank');
    win.focus();
    onReset();
    // signUp({ variables })
    //   .then(data => {
    //     onReset();
    //     setSubmitted(true);
    //   })
    //   .catch(e => {
    //     if (/user_emails_email_key/.test(e.message)) {
    //       onReset();
    //       setSubmitted(true);
    //     } else {
    //       console.error(e);
    //     }
    //   });
  });

  if (submitted) {
    return (
      <Wrapper id="signup-form" as="form" onSubmit={handleSubmit}>
        <Box
          maxWidth={['100%', '100%', '880px']}
          margin="0 auto"
          position="relative"
          zIndex="1"
        >
          <Box px="7" textAlign="center">
            <Typography variant="display" color="light">
              {websiteCopy('EARLY_ACCESS_WAITLIST_TEXT')}
            </Typography>
            <Typography color="light" mt={['3']}>
              {websiteCopy('EARLY_ACCESS_WAITLIST_DESC')}
            </Typography>
          </Box>
        </Box>
      </Wrapper>
    );
  }

  return (
    <Wrapper id="signup-form" as="form" onSubmit={handleSubmit}>
      <Box
        maxWidth={['100%', '100%', '880px']}
        margin="0 auto"
        position="relative"
        zIndex="1"
      >
        <Box px="7" textAlign="center">
          <Typography variant="display" color="light">
            {websiteCopy('EARLY_ACCESS_TEXT')}
          </Typography>
          <Typography color="light" mt={['3']}>
            {websiteCopy('EARLY_ACCESS_DESC')}
          </Typography>
        </Box>
        <Field mt={['48px']}>
          <Input
            value={formValues.email}
            onChange={onChanges.email}
            error={formErrors.email}
            fluid
            id="email"
            name="email"
            type="text"
            placeholder="your@email.com"
          />
          <Field.Append>
            <Button bare type="submit" color="secondary" margin="0 auto">
              <Icon name="arrow-right" size={28} color="accent" />
            </Button>
          </Field.Append>
        </Field>
        {formErrors.email && <Field.Error>{formErrors.email}</Field.Error>}
      </Box>
      <Image
        src={logo}
        display={['none', 'none', 'none', 'block']}
        position="absolute"
        height="100%"
        top={0}
        right={0}
        zIndex="0"
      />
    </Wrapper>
  );
};
