import gql from 'graphql-tag';

export const SignUpMutation = gql`
  mutation SignUpMutation($email: String!) {
    signUp(input: { email: $email }) {
      token {
        accessToken
        roleId
      }
    }
  }
`;
