import React from 'react';
import { MainLayout } from '../layouts';
import { HomeFeatures } from '../components/pages/home/HomeFeatures';
import { HomeHero } from '../components/pages/home/HomeHero';
import { SignUp } from '../components/pages/shared/SignUp';
import { SiteMetadata } from '../components/site-metadata';

export default () => (
  <MainLayout>
    <SiteMetadata pathname="/" />
    <HomeHero />
    <HomeFeatures />
    <SignUp />
  </MainLayout>
);
