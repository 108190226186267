import { useState, useMemo, useCallback } from 'react';
import useWatchRef from './useWatchRef';

export const useForm = (initialValues, validates) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const refValues = useWatchRef(values);

  const onChanges = useMemo(
    () =>
      Object.keys(values).reduce(
        (memo, key) => ({
          ...memo,
          [key]: event => {
            const value = event.target.value;
            setValues(prevValues => {
              return { ...prevValues, [key]: value };
            });
            setErrors({});
          },
        }),
        {}
      ),
    [values]
  );

  const onReset = useCallback(() => {
    setValues(initialValues);
    setErrors({});
  }, [initialValues]);

  const onBlurs = useMemo(
    () =>
      Object.keys(initialValues).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: () => {
            const validate = validates[curr];
            if (validate) {
              const error = validate(refValues.current);
              if (error) {
                setErrors(prevErrors => ({
                  ...prevErrors,
                  [curr]: error,
                }));
              }
            }
          },
        }),
        {}
      ),
    [initialValues, refValues, validates]
  );

  const createOnSubmit = useCallback(
    handleSubmit => {
      return event => {
        event.preventDefault();

        if (validates) {
          const tempErrors = Object.keys(validates).reduce((prev, curr) => {
            const validate = validates[curr];
            const error = validate(refValues.current);
            if (error) {
              return {
                ...prev,
                [curr]: error,
              };
            }
            return prev;
          }, {});

          setErrors(tempErrors);
          if (Object.keys(tempErrors).length === 0) {
            handleSubmit(refValues.current);
          }
        } else {
          handleSubmit(refValues.current);
        }
      };
    },
    [refValues, validates]
  );

  return {
    formValues: values,
    formErrors: errors,
    onChanges,
    onReset,
    onBlurs,
    createOnSubmit,
  };
};
